<template>
  <div class="flex items-center">
    <input
      :name="id"
      :id="id"
      type="checkbox"
      value="true"
      :checked="!!modelValue"
      v-on:change="$emit('update:modelValue', !modelValue)"
      :disabled="disabled"
      class="checkbox"
      :class="{ 'cursor-not-allowed': disabled }"
    />

    <label
      :for="id"
      class="ml-2"
      :class="`${disabled ? 'cursor-not-allowed text-gray-400' : ''} ${label_class}`"
      ><slot></slot
    ></label>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    modelValue: Boolean,
    disabled: Boolean,
    label_class: {
      type: String,
      default: 'label'
    }
  },
  emits: ["update:modelValue"],
};
</script>
